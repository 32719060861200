import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import BankSelectValidation from '../../components/BankSelectValidation';
import otpValidation from "../../components/otpValidation";
import axios from 'axios';
import '../DBS/BankSelect.css';
import CreateAccKeys from '../../components/CreateAccKeys';

function UOBBankSelect(){
    const arrayData = JSON.parse(sessionStorage.getItem("AccountsDisplay"));
    const keys = CreateAccKeys(arrayData);
    const jsonData = Object.assign(...keys.map((element, index)=>({
        [element]: arrayData[index]
    })));
    
    const navigate__ = useNavigate();

    const [bankValid, setBankValid] = useState(false);
    const [bankValidErr, setBankValidErr] = useState({});
    const [bankValue, setBankValue] = useState({
        TicketID: '',
        bankAccount: ''
    });
    
    const [bsmodal, setBsmodal] = useState(false);
    const [bsmodal2, setBsmodal2] = useState(false);
    const [bsmodal3, setBsmodal3] = useState(false);
    const [email_sentence, setemail_sentence] = useState(false);
    const [OTP_front, setOTP_front] = useState(false);
    const [loadingmodal, setloadingmodal] = useState(false);
    const [requestMade, setRequestMade] = useState(false);
    const [successful, setSuccessful] = useState(0);

    const toggleBsmodal2 = () =>{
        setBsmodal2(!bsmodal2);
        setloadingmodal(!loadingmodal);
    };
  
    const closeAllModals = () => {
        setBsmodal(false); //with auth
        setBsmodal2(false);
        setBsmodal3(false);
        setloadingmodal(false);
        setRequestMade(true);
    };

    function whichModal(){
        if (sessionStorage.getItem('authEvent') === 'true') {
            setBsmodal2(false);
            setBsmodal(!bsmodal);
            sessionStorage.setItem('authEvent', 'false');
        };
    };

    function whichModal2(){
        console.log("test", sessionStorage.getItem("authEvent2"));
        if (sessionStorage.getItem('authEvent2') === 'true') {
            console.log("test2")
            setBsmodal3(!bsmodal3);
            setloadingmodal(false);
            sessionStorage.setItem('authEvent2', 'false');
        };
    };

    const [otpValid, setotpValid] = useState(false);
    const [otpVal, setotpVal] = useState({
        OTP: '',
    });

    const [errs, setErrs] = useState({});

    const onChange = (e) => {
        const inputValue = e.target.value.replace(/[^0-9]/g, "");
        setotpVal({...otpVal, [e.target.name]: inputValue});
    };

    useEffect(()=>{
        const validateOTP = otpValidation(otpVal, 0);
        setotpVal({...otpVal, 'TicketID': sessionStorage.getItem('TicketID')});
        setErrs(validateOTP);

        setotpValid(validateOTP.otp === "");
    }, [otpVal]);
    
    function checkForKey2InSS(){
        if (sessionStorage.getItem('ResultEvent') !== null && sessionStorage.getItem('ResultEvent') === 'completed') {
            let ticket5 = sessionStorage.getItem('TicketID');
            axios
                .get('https://aranpay.onrender.com/uob/result', {
                    params: {
                        TicketID : ticket5,
                    }
                })
                .then(res => {
                    console.log(res);
                    const statusResult = res.data[0].Result;
                    const errLogging = res.data[0].ErrorLog; 
                    const Status = res.data[0].Status;
                    console.log(statusResult);
                    console.log(Status);
                    console.log(errLogging);
                    if (statusResult === 'Success' && Status === 'completed') {
                        sessionStorage.setItem("Result", statusResult);
                        sessionStorage.setItem("Status", Status);

                        setSuccessful(1);
                        console.log(successful);
                        closeAllModals(); //success 
                    } else {
                        sessionStorage.setItem("Result", statusResult);
                        sessionStorage.setItem("Status", Status);
                        let Err_log = (res.data[0].ErrorLog).includes("Browser Error") ? "Unexpected Error has occured. Please try again" : res.data[0].ErrorLog;
                        Err_log = (res.data[0].ErrorLog).includes("Session Error") ? "It appears you're logged in from another device. Please log out and try again." : Err_log;
                        sessionStorage.setItem("Error", Err_log);
                        // sessionStorage.setItem("Error", errLogging);
                        // let ticket3 = sessionStorage.getItem('TicketID');

                        setSuccessful(2);
                        console.log(successful);
                        closeAllModals(); //success 
                    };
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };
    
    
    function checkForKey3InSS(){
        if (sessionStorage.getItem('authEvent2') === 'true') {
            let ticket5 = sessionStorage.getItem('TicketID');
            axios
                .get('https://aranpay.onrender.com/uob/authotp', {
                    params: {
                        TicketID : ticket5,
                    }
                })
                .then(res => {
                    console.log(res);
                    const otp = (res.data[0].AuthThree).split(",");
                    setemail_sentence(otp[0]);
                    setOTP_front(otp[1]);
                })
        };
    };

    useEffect(() => {
        if (!bsmodal && !bsmodal2 && !bsmodal3 && requestMade && successful === 1) {
            navigate__('/uob/successful');
        };
    }, [bsmodal, bsmodal2, bsmodal3, navigate__, requestMade, successful]);

    useEffect(() => {
        if (!bsmodal && !bsmodal2 && !bsmodal3 && requestMade && successful === 2) {
            navigate__('/uob/unsuccessful');
        };
    }, [bsmodal, bsmodal2, bsmodal3, navigate__, requestMade, successful]);

    useEffect(()=>{
        checkForKey2InSS();
        whichModal();
        // Listen for a custom event called 'sessionStorageUpdated' 
        window.addEventListener('updateSSResult', checkForKey2InSS); 
        window.addEventListener('AuthTwo', whichModal); 
        // Cleanup the event listener when the component unmounts 
        return () => { 
            window.removeEventListener('updateSSResult', checkForKey2InSS); 
            window.removeEventListener('AuthTwo', whichModal); 
        } 
    }, [sessionStorage]);

    useEffect(()=>{
        checkForKey3InSS();
        whichModal2();

        // Listen for a custom event called 'sessionStorageUpdated'
        window.addEventListener("AuthThree", () => {
            checkForKey3InSS();
            whichModal2();
        });
        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener("AuthThree", () => {
                checkForKey3InSS();
                whichModal2();
            });
        }
    }, [sessionStorage]);

    useEffect(() => {
        const bankValidationErrs = BankSelectValidation(bankValue);
        // console.log(bankValue);
        setBankValue({...bankValue, 'TicketID': sessionStorage.getItem('TicketID')})
        setBankValidErr(bankValidationErrs);

        setBankValid(bankValidationErrs.bankAccount === "");
    }, [bankValue]);
    
    const handleChange_ = (event) => {
        setBankValue({...bankValue, [event.target.name]: event.target.value});
    }

    //countdown timer:
    const [timer_, setTimer_] = useState(200);
    useEffect(()=> {
        const countdown_ = timer_ > 0 && setInterval(() => setTimer_(timer_ - 1), 1000);
        let submitted = sessionStorage.getItem("Submitted");
        if (submitted != "true"){
            if (timer_ === 15){
                let ticketid = sessionStorage.getItem("TicketID");
                const redirectData = {
                    setStatus: "completed",
                    TicketID: ticketid,
                };
                axios
                    .put("https://aranpay.onrender.com/emergency-exit",
                        redirectData
                    ).then((res_) => {
                        console.log(res_);
                    })
                    .catch((err_) => {
                        console.log(err_);
                    });          
            }
            else if (timer_ === 0) {
                sessionStorage.setItem("Error", "Timeout Due to Failure of Authentication");
                // navigate__("/uob/unsuccessful");
                setSuccessful(2);
                console.log(successful);
                closeAllModals();
            };            
        }
        return () => clearInterval(countdown_);
    },[timer_]);

    const handleSubmit_ = (e) => {
        e.preventDefault();
        if (sessionStorage.getItem('authEvent2') === null){
            if (bankValid) {
                console.log("Bank Selected");
                axios.put('https://aranpay.onrender.com/uob/accountnumber', bankValue)
                .then(res => {
                    console.log(res);
                    if (res !== "Error") {
                        sessionStorage.setItem("Submitted", "true");
                        console.log("Successful");
                    } else {
                        console.log("Unsuccessful");
                    }
                })
                .catch(err => console.log(err))
            }  else {
                console.log('Bank Account Not Selected');
            };
        } else if (sessionStorage.getItem('authEvent2') === 'false'){
            if (otpValid) {
                let ticket5 = sessionStorage.getItem("TicketID");
                const data = {
                    authotp: otpVal.OTP,
                    TicketID: ticket5
                }
                console.log(otpVal.OTP);
                axios.put('https://aranpay.onrender.com/uob/emailotp', data)
                .then(res => {
                    console.log(res);
                    if (res !== "Error") {
                        sessionStorage.setItem("Submitted", "true");
                        console.log("Successful");
                        setloadingmodal(true);
                    } else {
                        console.log("Unsuccessful");
                    }
                })
                .catch(err => console.log(err))
            }  else {
                console.log('Please fill in a valid OTP');
            };
        }
    }

    const Amount = sessionStorage.getItem("Amount");
    const Ticket = sessionStorage.getItem("TicketID");
    const Transaction_Time = sessionStorage.getItem("Datetime");

    return(
        <>
            <div className="header-container">
                <div className="time-left-container">
                    <label className='time-left'>Time Remaining: {timer_} Seconds</label>
                </div>
            </div>
            <div className="body-container">
                <div className="body-1-container">
                        <div className="amount-deposited-container">
                            <label className='inner-text'>Amount: ${Amount}</label>
                        </div>
                        <div className="amount-deposited-container">
                            <label className='inner-text'>Order No.: {Ticket}</label>
                        </div>
                        <div className="amount-deposited-container">
                            <label className='inner-text'>Transaction Time: {Transaction_Time}</label>
                        </div>
                    </div>
                <div className="body-2-container">
                    <div className="processes-container">
                        <img src={require('../../img/UOB-hiso-5.png')} className="hiso-6-img" alt="hiso-6-png"/>
                    </div>
                    <div className='dummy-div-bs'>
                        <div className="bank-select-form-container">
                            <form onSubmit={handleSubmit_} className='bank-sel-form'>
                                <label htmlFor='bankAcc' className='bank-select-label'>
                                    Please Select your Bank Account: 
                                </label>
                                <select name='bankAccount' id='bankAcc' className='bank-select-select' onChange={handleChange_}>
                                    <option value={''}>---Please Select Your Bank Account---</option>
                                    {Object.keys(jsonData).map((key, accountNo)=>(
                                        <option key={accountNo} value={jsonData[key]}>{jsonData[key]}</option>
                                    ))}
                                </select>
                                <div className='err-container'>
                                    {bankValidErr.bankAccount && <span className='error-text'>{bankValidErr.bankAccount}</span>}
                                </div>
                                <div className='button-container'>
                                    <button type="submit" className="bank-select-continue-btn" onClick={toggleBsmodal2} onSubmit={handleSubmit_}>Continue</button> 
                                    {bsmodal && (
                                    <div className='modal-container'>
                                        <div className='modal-overlay'>
                                            <div className='modal-content'>
                                                <div className='modal-1'>
                                                    <img src={require('../../img/uob-auth.png')} className="DBS-auth-img" alt="uob-auth"/>
                                                </div>
                                                <div className='modal-1'>
                                                    <label>Please check for a second authentication.</label>
                                                </div>
                                                <div className='modal-1'>
                                                    <label>Please Wait while we confirm your transaction. This can take up to a minute.</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                    {bsmodal2 && (
                                    <div className='modal-container'>
                                        <div className='modal-overlay'>
                                            <div className='modal-content'>
                                                <div className='modal-1'>
                                                    <div className='spinner-container'>
                                                        <div className='loading-spinner'></div>
                                                    </div>
                                                </div>
                                                <div className='modal-1'>
                                                    <label>Please Wait while we confirm your transaction. This can take up to a minute.</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                    {bsmodal3 && (
                                        <div className='modal-container'>
                                            <div className='modal-overlay'>
                                                <div className='modal-content'>
                                                    <div className='modal-1'>
                                                        {loadingmodal && (
                                                            <div className='modal-1'>
                                                                <div className='spinner-container'>
                                                                    <div className='loading-spinner'></div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <h2>Verify your Identity</h2>
                                                        <div className='w-full'><label>For your security, this transaction requires an additional authentication. To continue, enter the One-Time Password (OTP) that was sent to you.</label></div>
                                                        <div className='w-full'><label></label></div>
                                                        <div className='err-container'></div>
                                                        <div className='w-full'><label>{email_sentence}</label></div>
                                                        <div className='err-container'></div>

                                                        <div className='p-3'>
                                                            <label>{OTP_front}</label><input type='text' placeholder='OTP' name='OTP' value={otpVal.OTP} onChange={onChange}/>
                                                            <div className='err-container'>
                                                                {errs.otp && <span className='error-text'>{errs.otp}</span>}
                                                            </div>
                                                            <div className='w-full'><label>This OTP expires after 3 minutes.</label></div>
                                                            <button type='submit' className='continue-btn' onClick={whichModal2} disabled={loadingmodal} onSubmit={handleSubmit_}>CONTINUE</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>                                
                            </form>
                        </div>
                    </div>
                </div>
                <div className='spacer'></div>
                <div className='reminder-container'> {/* move to center */}
                        <div className='reminder-list-list'>
                            <h2>Reminder</h2>   
                            <ol className='reminder-list'>
                                <li className='reminder-list-point'>Kindly ensure your bank account has been activated for online payment processing.</li>
                                <li className='reminder-list-point'>Please avoid logging into your bank account from multiple locations while a transaction is in progress.</li>
                                <li className='reminder-list-point'>Please do not click on any submit button more than once.</li>
                                <li className='reminder-list-point'>Please do not refresh your browser.</li>
                                <li className='reminder-list-point'>Please take note of the order number in the event you need to contact our Customer Service.</li>
                            </ol>
                        </div>
                </div>  

            </div>
        </>
    );
}

export default UOBBankSelect;